import {ThemeProvider, Hero} from '@primer/react-brand'
import {PlayIcon} from '@primer/octicons-react'
import {Spacer} from './components/Spacer'

const extURLs = {
  get_started: 'https://github.com/projects',
  video: 'https://www.youtube.com/watch?v=o1wuW24Nv4E',
}

export function IssuesIndex() {
  return (
    <ThemeProvider colorMode="dark" className="fp-hasFontSmoothing">
      {/* Header + Subnav = 136px */}
      <Spacer size="136px" />
      <Spacer size="48px" size1012="96px" />

      {/* Section Hero */}

      <section id="hero">
        <div className="fp-Section-container">
          <Hero data-hpc className="fp-Hero">
            <Hero.Label color="purple">GitHub Issues</Hero.Label>
            <Hero.Heading size="2">Project planning for developers</Hero.Heading>
            <Hero.Description size="300">
              Create issues, break them into tasks, track relationships, add custom fields, and have conversations.
              Visualize large projects as tables, boards, or roadmaps, and automate everything with code.
            </Hero.Description>
            <Hero.PrimaryAction href={extURLs.get_started}>Start using projects</Hero.PrimaryAction>
            <Hero.SecondaryAction href={extURLs.video} trailingVisual={<PlayIcon />}>
              What is GitHub Issues
            </Hero.SecondaryAction>
            <Hero.Image src="/images/modules/site/issues/fp24/hero.webp" alt="TODO" />
          </Hero>
        </div>
      </section>

      <Spacer size="64px" size1012="128px" />
    </ThemeProvider>
  )
}

try{ IssuesIndex.displayName ||= 'IssuesIndex' } catch {}